import React, { useState } from "react"
import Layout from "../components/layout"
import config from "../utils/siteConfig"
import Button from "../components/Atoms/Button/Button"
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight, faDatabase, faPlay, faIndustry } from "@fortawesome/free-solid-svg-icons"

export default function WorkplaceApp({ data }) {
    debugger;
    const page = data.allContentfulZSolutions.edges[0].node

    const Bold = ({ children }) => <span className="bold3">{children}</span>
    //const Text = ({ children }) => <p className="align-center">{children}</p>;

    const options = {
        renderNode: {
            [BLOCKS.HEADING_1]: (node, children) => (
                <h1 className="heading1">{children}</h1>
            ),
            [BLOCKS.HEADING_2]: (node, children) => (
                <h2 className="heading2">{children}</h2>
            ),
            [BLOCKS.HEADING_3]: (node, children) => (
                <h3 className="h1-medium-rtxt h1-darkBlue">{children}</h3>
            ),
            [BLOCKS.HEADING_4]: (node, children) => (
                <h4 className="h1-medium2Medium h1-darkBlue">{children}</h4>
            ),
            [BLOCKS.HEADING_5]: (node, children) => (
                <h4 className="h5-medium-rtxt text-darkgray2">{children}</h4>
            ),
            [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
                <img src={`https:${node.data.target.fields.file["en-US"].url}`} />
            ),
            [BLOCKS.PARAGRAPH]: (node, children) => (
                <p className="">{children}</p>
            ),
            [BLOCKS.UL_LIST]: (node, children) => {
                return (
                    <ul
                        style={{
                            listStyle: 'disc',
                            lineHeight: '35px',
                            marginLeft: '1rem',
                        }}
                    >
                        {children.map((item) => (
                            <li key={item.key}>{item.props.children[0].props.children[0]}</li>
                        ))}
                    </ul>
                );
            },
            //[BLOCKS.UL_LIST]: (node, children) => (
            //    <span className="d-inline-block bulletSpan">{children}</span>
            //),

        },
        renderMark: {
            [MARKS.BOLD]: text => <Bold>{text}</Bold>,
        },
        renderText: (text) =>
            text
                .replace(/\u2028/g, "")
                .split("\n")
                .flatMap((text, i) => [i > 0 && <br />, text])
    }

    const [toggleState, setToggleState] = useState(1);

    const toggleTab = (index) => {
        setToggleState(index);
    };

    const {
        id,
        metaData,
        slHBtn,
        slHHd,
        slHHd2,
        slHTxt,
        slS1Rtxt,
        slS2Rtxt,
        slS3Rtxt,
        slS4Rtxt,
        slHImage,
        slS1Image,
        slS2Image,
        slS3Image,
        slS4Image,
    } = page


    return (
        <Layout
            IsHeaderImageDark={false}
            navColour={"white"}>
            <head>
                <title>{metaData.title}</title>
                <meta charset="utf-8" />
                <meta name="description" content={metaData.description} />
                <meta name="keywords" content={metaData.keywords.keywords} />
                <link rel="alternate" hreflang="de" href="https://wunderloop.io/de/workplace-mobile-app" />
                <link rel="alternate" hreflang="en" href="https://wunderloop.io/en/workplace-mobile-app" />
            </head>

            {/*Header section*/}
            <section className="bg-white">
                <div className="head-container2">
                    <div className="row align-items-center">
                        <div className="col-lg-8">
                            <div className="bg-white">
                                <div className="row">
                                    <h1 className="align-top h1-small h1-purple">
                                        {slHHd2}
                                    </h1>
                                </div>
                                <div className="row pt-5 pr-4">
                                    <h1 className="h1-largeMedium2 pt-3 h1-darkBlue">
                                        {slHHd}
                                    </h1>
                                </div>
                                <div className="row pt-4 pr-4">
                                    <h2 className="h2-large text-darkgray">
                                        {slHTxt.slHTxt}
                                    </h2>
                                </div>
                                <div class="row pt-4">
                                    <div class="text-center">
                                        <Button
                                            className='btns'
                                            buttonStyle='btn--outlinePurple'
                                            buttonSize='btn--large'
                                            href={config.navigationURL_register}
                                        >
                                            {slHBtn}{" "}
                                            <FontAwesomeIcon className="" size="1x" icon={faChevronRight} className="faChevronRight" />
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex__wrapper col-lg-4 bg-white pt-5">
                            <img src={slHImage.file.url} className="img-fluid" alt={slHImage.title} />
                        </div>
                    </div>
                </div>
            </section>

            {/*Section 1*/}
            <section className="bg-lightgray">
                <div className="sectionFit-container bg-lightgray">

                    <div className="row">
                        <div className="col-lg-6 pb-5">
                            <div className="row pr-5 pt-4">
                                <h2 className="h2-large text-darkgray pb-3 pr-5">
                                    {documentToReactComponents(slS1Rtxt.json, options)}
                                </h2>
                            </div>
                        </div>
                        <div className="col-lg-6 pb-5">
                            <img src={slS1Image.file.url} className="img-fluid" alt={slS1Image.title} />
                        </div>
                    </div>
                </div>
            </section>

            {/*Section 2*/}
            <section className="bg-white ">
                <div className="sectionFit-container bg-white ">
                    <div className="row align-items-center ">
                        <div className="col-lg-6 pb-5">
                            <div className="pr-5">
                                <img src={slS2Image.file.url} className="img-fluid" alt={slS2Image.title} />
                            </div>
                        </div>
                        <div className="col-lg-6 pb-5">
                            <div className="row pr-5 pt-4">
                                <h2 className="h2-large text-darkgray pb-3 pr-5">
                                    {documentToReactComponents(slS2Rtxt.json, options)}
                                </h2>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            {/*Section 3*/}
            <section className="bg-lightgray">
                <div className="sectionFit-container bg-lightgray">
                    <div className="row align-items-center">
                        <div className="col-lg-6 pb-5">
                            <div className="pr-5">
                                <img src={slS3Image.file.url} className="img-fluid" alt={slS3Image.title} />

                            </div>
                        </div>
                        <div className="col-lg-6 pb-5">
                            <div className="row pr-5 pt-4">
                                <h2 className="h2-large text-darkgray pb-3 pr-5">
                                    {documentToReactComponents(slS3Rtxt.json, options)}
                                </h2>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            {/*Section 4*/}

            <section className="bg-white">
                <div className="sectionFit-container bg-white">
                    <div className="row align-items-center">
                        <div className="col-lg-6 pb-5">
                            <div className="row pr-5 pt-4">
                                <h2 className="h2-large text-darkgray pb-3 pr-5">
                                    {documentToReactComponents(slS4Rtxt.json, options)}
                                </h2>
                            </div>
                        </div>
                        <div className="col-lg-6 pb-5">
                            <div className="">
                                <img src={slS4Image.file.url} className="img-fluid" alt={slS4Image.title} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>



        </Layout >
    );
}


export const query = graphql`
query($locale: String!) {
    allContentfulZSolutions(
        filter: {
        node_locale: { eq: $locale },
        id: {in: ["008a8652-65f1-5631-a9d0-003740b18ebe", "683c4d0d-01c5-541d-a072-7ac2e32163db"]}
    }) {
        edges {
            node {
                id
                metaData {
                    slug
                    title
                    description
                    keywords {
                        keywords
                    }
                }
                slHBtn
                slHHd
                slHHd2
                slHTxt {
                    slHTxt
                }
                slS1Rtxt {
                    json
                }
                slS2Rtxt {
                    json
                }
                slS3Rtxt {
                    json
                }
                slS4Rtxt {
                    json
                }
                slHImage {
                    file {
                      url
                      fileName
                    }
                    description
                    title
                  }
                  slS1Image {
                    description
                    title
                    file {
                      fileName
                      url
                    }
                  }
                  slS2Image {
                    description
                    title
                    file {
                      url
                      fileName
                    }
                  }
                  slS3Image {
                    description
                    title
                    file {
                      url
                      fileName
                    }
                  }
                  slS4Image {
                    file {
                      fileName
                      url
                    }
                    description
                    title
                  }
            }
        }
    }
}
`
